import 'dayjs/locale/en';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import durationPlugin from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';

import { FlowFrequencyOptions } from './types/flowTypes';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(utcPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(localizedFormat);

export function getRemainingTime(utcDueDate: Date): string {
  const dueDate = dayjs(dayjs.utc(utcDueDate).local());
  const diffDuration = dayjs.duration(dueDate.diff(dayjs()));

  const diffAsMinutes = diffDuration.asMinutes();
  const diffAsHours = diffDuration.asHours();
  const diffAsDays = diffDuration.asDays();

  const diffMinutes = diffDuration.minutes();
  const diffHours = diffDuration.hours();
  const diffDays = diffDuration.days();
  const diffWeeks = diffDuration.weeks();

  const remainingDiffDays = diffDays % 7;

  if (diffAsMinutes < 1) {
    return `<1min`;
  }

  if (diffAsMinutes >= 1 && diffAsHours < 1) {
    return `${diffMinutes}min`;
  }

  if (diffAsHours >= 1 && diffAsDays < 1) {
    return diffMinutes === 0
      ? `${diffHours}h`
      : `${diffHours}h ${diffMinutes}min`;
  }

  if (diffAsDays >= 1 && diffAsDays < 7) {
    return diffHours === 0 ? `${diffDays}d` : `${diffDays}d ${diffHours}h`;
  }

  return remainingDiffDays === 0
    ? `${diffWeeks}w`
    : `${diffWeeks}w ${remainingDiffDays}d`;
}

export function formatDate(date: Date | string | null, locale = 'en') {
  const dateToFormat = date ?? new Date();
  return dayjs(dateToFormat).locale(locale).format('L [at] LT');
}

/** Used to display timestamps in actual local time in  tooltips across UIs */
export function formatToLocalDateTime(
  dateToFormat: Date | string,
  timeZone?: string
) {
  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date = dayjs(dateToFormat).tz(timeZone ?? defaultTimezone);
  return date.format(`MMMM Do, YYYY [at] h:mma`);
}

export function reduceMinutesFromDeadline(deadlineInMinutes: number) {
  return deadlineInMinutes - 5;
}

export function getWeekNumberOfMonth(date: Date) {
  const day = dayjs(date);
  const firstDayOfMonth = day.startOf('month');
  const weekDiff = day.diff(firstDayOfMonth, 'week');
  return weekDiff + 1;
}

export function formatIsoDateStringToTimeString(isoDate: string) {
  const date = new Date(isoDate);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${hours}:${minutes} ${ampm}`;
}

export function getMinutesLimit(selectedFrequencyOption: FlowFrequencyOptions) {
  switch (selectedFrequencyOption) {
    case FlowFrequencyOptions.Daily:
    case FlowFrequencyOptions.Once:
    case FlowFrequencyOptions.EveryWeekday:
      return 1440;
    case FlowFrequencyOptions.Weekly:
      return 10080;
    case FlowFrequencyOptions.BiWeekly:
      return 20160;
    case FlowFrequencyOptions.Monthly:
    case FlowFrequencyOptions.MonthlyLast:
      return 40320;
    case FlowFrequencyOptions.Quarterly:
    case FlowFrequencyOptions.QuarterlyLast:
    default:
      return 44640;
  }
}

export function getBrowserTimeZone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Some browsers may return 'Asia/Calcutta' instead of the more modern 'Asia/Kolkata'
  if (timeZone === 'Asia/Calcutta') {
    return 'Asia/Kolkata';
  }

  return timeZone;
}
