import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export function useTimeOfDay(initialHour: number) {
  const [timeOfDay, setTimeOfDay] = useState<
    'morning' | 'afternoon' | 'evening' | 'night'
  >('morning');

  useEffect(() => {
    const updateTimeOfDay = (hour: number) => {
      if (hour >= 4 && hour < 12) {
        setTimeOfDay('morning');
      } else if (hour <= 18) {
        setTimeOfDay('afternoon');
      } else if (hour <= 21) {
        setTimeOfDay('evening');
      } else {
        setTimeOfDay('night');
      }
    };

    updateTimeOfDay(initialHour);

    const intervalId = setInterval(
      () => {
        const currentHour = dayjs().hour();
        updateTimeOfDay(currentHour);
      },
      60 * 60 * 1000
    );
    return () => clearInterval(intervalId);
  }, [initialHour]);

  return timeOfDay;
}
