import type {
  DeadlineType,
  FlowResponseType,
  RemindersType,
  ResponseFrequencyType,
} from '@assembly-web/services';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import * as Accordion from '@radix-ui/react-accordion';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

const messages = defineMessages({
  blockSectionTitle: {
    defaultMessage: 'Create and edit the content of this flow',
    id: 'GBlFp/',
  },
  distributionSectionTitle: {
    defaultMessage: `Set up this flow's distribution and reminders`,
    id: 'o82eyD',
  },
  distributionSubTitle: {
    defaultMessage: `Current settings: {responseType, select,
      anytime {Always open}
      deadline {On a deadline{deadlineType, select,
        manual {{reminderType, select,
          manual {, Manual Reminder}
          other {}
        }}
        other {, {reminderType, select,
          manual {Manual Reminder}
          other {{responseFrequencyType, select,
            recurring {{duration} reminders on {time} {timeZone}}
            other {{duration} reminder on {time} {timeZone}}
          }}
        }}
      }}
      milestone {On a milestone}
      other {{responseType}}
    }{responseType, select,
      deadline {}
      milestone {}
      other {, {reminderType, select,
        manual {Manual Reminder}
        other {{responseFrequencyType, select,
          recurring {{duration} reminders on {time} {timeZone}}
          other {{duration} reminder on {time} {timeZone}}
        }}
      }}
    }`,
    id: 'wwsOTX',
  },
  currentSettingsTitle: {
    defaultMessage: 'Current settings {response} {time}',
    id: 'c0nbrV',
  },
});

function Content({ className, ...tail }: Accordion.AccordionContentProps) {
  return (
    <Accordion.Content
      {...tail}
      className={twMerge('flex flex-col', className)}
    />
  );
}

function Trigger({
  className,
  index,
  title,
  subTitle,
  ...tail
}: Omit<Accordion.AccordionTriggerProps, 'asChild' | 'children'> & {
  index: number;
  title: ReactNode;
  subTitle?: ReactNode;
}) {
  return (
    <Accordion.Header className="w-full">
      <Accordion.Trigger
        className={twMerge(
          'group flex w-full gap-2 p-4 data-[state=open]:border-b data-[state=open]:border-gray-5 data-[state=open]:bg-gray-9',
          className
        )}
        {...tail}
      >
        <ChevronDownIcon className="mt-0.5 h-6 w-6 stroke-current text-gray-9 will-change-transform group-data-[state=open]:rotate-180 group-data-[state=open]:text-gray-1" />
        <TextStyle
          as="span"
          className="mt-0.5 flex h-6 w-6 items-center justify-center rounded-full bg-gray-4 group-data-[state=open]:bg-primary-6 group-data-[state=open]:text-gray-1"
          variant="base-medium"
        >
          {index}
        </TextStyle>
        <div className="flex flex-1 flex-col items-start justify-start text-left group-data-[state=open]:text-gray-1">
          <TextStyle as="span" variant="lg-medium">
            {title}
          </TextStyle>
          {Boolean(subTitle) && (
            <TextStyle
              as="span"
              variant="base-regular"
              className="line-clamp-1"
            >
              {subTitle}
            </TextStyle>
          )}
        </div>
      </Accordion.Trigger>
    </Accordion.Header>
  );
}

function Item({ className, ...tail }: Accordion.AccordionItemProps) {
  return (
    <Accordion.Item
      {...tail}
      className={twMerge(
        'box-content overflow-hidden rounded-lg border-gray-5 data-[state=closed]:border @[700px]/body:border',
        className
      )}
    />
  );
}

function Root({
  className,
  ...tail
}: Omit<Accordion.AccordionSingleProps, 'type'>) {
  return (
    <Accordion.Root
      {...tail}
      className={twMerge('flex flex-col gap-4', className)}
      type="single"
      collapsible
    />
  );
}

function BlocksItem({ children }: { children: ReactNode }) {
  const { formatMessage } = useIntl();

  return (
    <Item value="blocks">
      <Trigger index={1} title={formatMessage(messages.blockSectionTitle)} />
      <Content className="bg-gray-3 data-[state=open]:px-2 data-[state=open]:pb-4 data-[state=open]:pt-3 @[700px]/body:data-[state=open]:px-8">
        {children}
      </Content>
    </Item>
  );
}

function DistributionsItem({
  time,
  timeZone,
  children,
  frequency,
  reminderType,
  responseType,
  deadlineType,
  responseFrequencyType,
}: {
  time: string;
  timeZone: string;
  frequency: string;
  children: ReactNode;
  deadlineType: DeadlineType;
  reminderType: RemindersType;
  responseType: FlowResponseType;
  responseFrequencyType: ResponseFrequencyType;
}) {
  const { formatMessage } = useIntl();

  return (
    <Item value="distributions">
      <Trigger
        index={2}
        title={formatMessage(messages.distributionSectionTitle)}
        subTitle={formatMessage(messages.distributionSubTitle, {
          time,
          timeZone,
          reminderType,
          responseType,
          deadlineType,
          duration: frequency,
          responseFrequencyType,
        })}
      />
      <Content className="bg-gray-3 data-[state=open]:px-2 data-[state=open]:pb-4 data-[state=open]:pt-3 @[700px]/body:data-[state=open]:px-8">
        {children}
      </Content>
    </Item>
  );
}

export const SectionAccordion = {
  Item,
  Root,
  Trigger,
  Content,
  BlocksItem,
  DistributionsItem,
};
