import { CalendarDate, getLocalTimeZone, today } from '@internationalized/date';

export function getEndOfDay(date: Date) {
  const endOfDay = new Date(date);

  endOfDay.setHours(23, 59, 59, 999);

  return endOfDay;
}

export function getCalendarDate(date: Date) {
  return new CalendarDate(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
  );
}

export function isSameCalendarDate(a: Date, b: Date) {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}

export function getTodayCalendarDate() {
  return today(getLocalTimeZone());
}
