import {
  type ChallengeDetails,
  type SubmitClaimFormDetails,
  useUserDetails,
} from '@assembly-web/services';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl';
import { z } from 'zod';

import { InlineError } from '../../../DesignSystem/Feedback/InlineError';
import { OverflowText } from '../../../DesignSystem/Feedback/OverflowText';
import { FilePreview } from '../../Shared/FileUpload/FilePreview';
import type { useFileUpload } from '../../Shared/FileUpload/useFileUpload';
import {
  RichTextEditor,
  type SerializedData,
} from '../Editors/RichTextEditor/RichTextEditor';
import { FilePreviewerCarousel } from '../FilePreviewer';

export type SubmitClaimData = {
  text: {
    plainText: string;
    messageTokens: string;
    messageHtml: string;
  };
  files: {
    name: string;
    size: number;
    location: string;
  }[];
};

type SubmitChallengeFormProps = {
  challengeDetails: ChallengeDetails;
  onSubmit: (data: SubmitClaimData) => void;
  formMeta: SubmitClaimFormDetails;
  fileUploadOptions: ReturnType<typeof useFileUpload>;
  onFormStateChange: (
    isValid: boolean,
    isDirty: boolean,
    errors: string
  ) => void;
};

const messages = defineMessages({
  openEndedBlockLabel: {
    defaultMessage: 'Describe what you did',
    id: 'M+6U7a',
  },
  fileUploadBlockLabel: {
    defaultMessage: 'Upload a screenshot of your review',
    id: 'WgQFiX',
  },
  openEndedBlockPlaceholder: {
    defaultMessage: 'Describe why and how you completed this challenge...',
    id: 'dV6eSt',
  },
  uploadFilesLabel: {
    defaultMessage: 'Click to upload or drag and drop to upload files',
    id: '7fINdH',
  },
  uploadLimitLabel: {
    defaultMessage: 'Maximum: 1 GB or 15 files',
    id: 'ezKSpM',
  },
  addMediaLabel: {
    defaultMessage: 'Add media',
    id: 'upjYHV',
  },
});

const errorMessages = defineMessages({
  fieldRequired: {
    defaultMessage: 'The text is required',
    id: 'Rfc/RO',
  },
  tooLong: {
    defaultMessage: 'Content should be less than 1000 characters',
    id: 'mhx8mR',
  },
  fileMandatoryMessage: {
    defaultMessage: 'At least one file is required to complete this challenge',
    id: 'NNXgwS',
  },
  addMediaLabel: {
    defaultMessage: 'Add media',
    id: 'upjYHV',
  },
});

const getFormSchema = (
  challengeDetails: ChallengeDetails,
  formatMessage: (descriptor: MessageDescriptor) => string
) => {
  const { proof } = challengeDetails;
  const hasFileContent = proof.content?.find(
    (content) => content.type === 'FILE'
  );
  const hasTextContent = proof.content?.find(
    (content) => content.type === 'TEXT'
  );

  if (hasFileContent && hasTextContent) {
    return z.object({
      openEnded: z
        .string()
        .trim()
        .min(1, formatMessage(errorMessages.fieldRequired))
        .max(1000, formatMessage(errorMessages.tooLong)),
      files: z
        .array(
          z.object({
            name: z.string(),
            size: z.string(),
            type: z.string(),
            location: z.string(),
          })
        )
        .min(1, formatMessage(errorMessages.fileMandatoryMessage)),
    });
  }

  if (hasFileContent) {
    return z.object({
      files: z
        .array(
          z.object({
            name: z.string(),
            size: z.string(),
            type: z.string(),
            location: z.string(),
          })
        )
        .min(1, formatMessage(errorMessages.fileMandatoryMessage)),
    });
  }

  return z.object({
    openEnded: z
      .string()
      .trim()
      .min(1, formatMessage(errorMessages.fieldRequired))
      .max(1000, formatMessage(errorMessages.tooLong)),
  });
};

export function SubmitChallengeForm(props: SubmitChallengeFormProps) {
  const { formatMessage } = useIntl();
  const userDetails = useUserDetails();

  const {
    onSubmit,
    formMeta,
    onFormStateChange,
    fileUploadOptions,
    challengeDetails,
  } = props;
  const { removeFile, files, clear, DragAndDrop } = fileUploadOptions;
  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: zodResolver(getFormSchema(challengeDetails, formatMessage)),
    mode: 'onChange',
  });

  const [editorContent, setEditorContent] = useState<SerializedData>({
    html: '',
    json: '',
    plainText: '',
  });

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const validationErrors = useMemo(() => {
    return Object.values(errors)
      .map((error) => {
        if (error) {
          return error.message;
        }
      })
      .join(',');
  }, [errors]);

  useEffect(() => {
    onFormStateChange(isValid, isDirty, validationErrors);
  }, [isValid, isDirty, onFormStateChange, validationErrors]);

  useEffect(() => {
    if (files.length) {
      setValue(
        'files',
        files.map(
          (file) => ({
            size: file.size?.toString() ?? '',
            name: file.name ?? '',
            type: file.type,
            location: file.meta.location as string,
          }),
          {
            isDirty: true,
            shouldValidate: true,
          }
        )
      );

      const { proof } = challengeDetails;
      const hasFileContent = proof.content?.find(
        (content) => content.type === 'FILE'
      );
      const hasTextContent = proof.content?.find(
        (content) => content.type === 'TEXT'
      );

      if (hasFileContent && !hasTextContent) {
        setValue('openEnded', '', {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    } else {
      setValue('files', [], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }

    trigger('files');
  }, [challengeDetails, files, setValue, trigger]);

  const submitHandler = () => {
    onSubmit({
      text: {
        messageHtml: editorContent.html,
        messageTokens: editorContent.json,
        plainText: editorContent.plainText,
      },
      files: files.map((file) => ({
        size: file.size ?? 0,
        name: file.name ?? '',
        location: file.meta.location as string,
      })),
    });
  };

  const error = errors['openEnded']?.message;

  const { formType, textFieldLabel, fileFieldLabel } = formMeta;

  return (
    <form
      id="challenge-submission-form"
      className="flex flex-col gap-4"
      onSubmit={handleSubmit(submitHandler)}
    >
      <section className="flex flex-col gap-2">
        {(formType === 'onlyOpenEnded' ||
          formType === 'fileUploadAndOpenEnded') && (
          <>
            <OverflowText
              variant="sm-medium"
              className="!block truncate"
              tooltipText={textFieldLabel}
              tooltipClassname="h-auto break-all overflow-hidden"
            >
              {textFieldLabel ?? formatMessage(messages.openEndedBlockLabel)}
            </OverflowText>
            <Controller
              control={control}
              name="openEnded"
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  onChange={(editorContent) => {
                    const { plainText } = editorContent;
                    setEditorContent(editorContent);
                    onChange(plainText);
                  }}
                  placeholder={
                    textFieldLabel ??
                    formatMessage(messages.openEndedBlockLabel)
                  }
                  hasError={Boolean(error)}
                  editorClassName="max-h-24"
                />
              )}
            />
          </>
        )}
        {typeof error === 'string' && (
          <InlineError id="openEnded-error" size="xs" as="span">
            {error}
          </InlineError>
        )}
      </section>
      {(formType === 'onlyFileUpload' ||
        formType === 'fileUploadAndOpenEnded') && (
        <>
          <OverflowText
            variant="sm-medium"
            className="!block truncate"
            tooltipText={fileFieldLabel}
            tooltipClassname="h-auto break-all overflow-hidden"
          >
            {fileFieldLabel ?? formatMessage(messages.addMediaLabel)}
          </OverflowText>
          <section className="flex w-full">
            <DragAndDrop
              title={formatMessage(messages.uploadFilesLabel)}
              subTitle={formatMessage(messages.uploadLimitLabel)}
            />
          </section>

          {files.length > 0 && (
            <div className="grid max-h-40 grid-cols-[1fr_1fr] gap-4 overflow-y-auto py-2 pr-3">
              <AnimatePresence>
                {files.map((file, index) => (
                  <motion.div
                    key={file.id}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                  >
                    <FilePreview
                      uppyFile={file}
                      variant="small"
                      onRemoveFileClick={removeFile}
                      onClick={() => {
                        setSelectedIndex(index);
                        setShowPreview(true);
                      }}
                    />
                  </motion.div>
                ))}
                {showPreview ? (
                  <FilePreviewerCarousel
                    files={files.map((file) => ({
                      fileName: file.name ?? '',
                      locationUrl: URL.createObjectURL(file.data),
                      fileType: file.type,
                      memberID: userDetails.data?.member.memberId ?? '',
                      memberName: userDetails.data?.member.name ?? '',
                      handleGoToPost: () => {
                        setShowPreview(false);
                      },
                      memberImage: userDetails.data?.member.image,
                      dateShared: new Date().toISOString(),
                      sharedIn: challengeDetails.title,
                      handleSharedInClick: () => {
                        setShowPreview(false);
                      },
                    }))}
                    isChallengeClaim
                    startFileIndex={selectedIndex}
                    onClose={() => setShowPreview(false)}
                    isUploading
                  />
                ) : null}
              </AnimatePresence>
            </div>
          )}
        </>
      )}
    </form>
  );
}
