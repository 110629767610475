import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { getUserDetailsQuery } from '../queries/getUserDetailsQuery';
import type { MemberAPIResponse, UserDetails } from '../types/domain';
import {
  getCanUserCreateFlows,
  getCanUserInviteNewUsers,
  getCanUserPostAnnouncements,
  getHasProfanityTurnedOn,
  getWorkspaceSlugPath,
} from '../workspace';

const updatedUserDetails = (data: MemberAPIResponse) => {
  return {
    member: {
      ...data.member,
      profile: {
        ...data.member.profile,
        fullName:
          `${data.member.profile.firstName} ${data.member.profile.lastName}`.trim(),
      },
      permissions: {
        canPostAnnouncements: getCanUserPostAnnouncements(data),
        canCreateFlow: getCanUserCreateFlows(data),
        canInviteNewUsers: getCanUserInviteNewUsers(data),
        profanitySetting: getHasProfanityTurnedOn(data),
      },
    },
    assembly: {
      ...data.assembly,
      workspaceSlugPath: getWorkspaceSlugPath(data),
    },
  };
};

export function useUserDetails(options?: UseQueryOptions<MemberAPIResponse>) {
  return useQuery({
    ...options,
    ...getUserDetailsQuery(options),
    select: (data: MemberAPIResponse): UserDetails => {
      return updatedUserDetails(data);
    },
  });
}

export function useSuspenseUserDetails(
  options?: UseQueryOptions<MemberAPIResponse>
) {
  return useSuspenseQuery({
    ...options,
    ...getUserDetailsQuery(options),
    select: (data: MemberAPIResponse): UserDetails => {
      return updatedUserDetails(data);
    },
  });
}
