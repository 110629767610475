import {
  type Icon,
  mapHexCodeToEmoticon,
  useUserDetails,
} from '@assembly-web/services';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Dialog from '@radix-ui/react-dialog';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import {
  Avatar,
  AvatarSize,
  IconButton,
  OverflowText,
  TextStyle,
  Tooltip,
  useDeviceInfo,
} from '../../../..';
import { messages } from '../FilePreviewer';

export const Header = ({
  fileName,
  onClose,
  memberID,
  memberName,
  isUploading,
  memberImage,
  dateShared,
  sharedIn,
  handleSharedInClick,
  sharedInIcon,
}: {
  fileName: string;
  onClose: () => void;
  memberID: string;
  memberName: string;
  isUploading: boolean;
  memberImage?: string;
  dateShared: string | number | Date;
  sharedIn: string;
  handleSharedInClick: () => void;
  sharedInIcon?: Icon;
}) => {
  const { formatMessage } = useIntl();
  const userDetails = useUserDetails();

  const isCurrentUser = memberID === userDetails.data?.member.memberId;

  const isMobile = useDeviceInfo().deviceType === 'mobile';

  const formattedDateShared = new Date(dateShared).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <Dialog.Title asChild className="leading-6">
      <header className="flex w-full flex-shrink-0 items-start justify-between rounded-t-2xl border-b border-gray-5 bg-gray-1 p-3">
        <div className="flex w-4/5 justify-start gap-x-3">
          <Avatar
            memberID={memberID}
            name={memberName}
            size={AvatarSize.ExtraLarge}
            image={memberImage}
          />
          <div className="flex w-full flex-col gap-y-3">
            <div
              className={twMerge(
                isMobile ? 'flex-wrap' : 'pt-2.5',
                'flex flex-1 items-center gap-x-2'
              )}
            >
              <TextStyle
                variant={isMobile ? 'sm-medium' : 'base-medium'}
                className="text-nowrap"
              >
                {isCurrentUser || !memberName
                  ? formatMessage(messages.you)
                  : memberName}
              </TextStyle>
              <TextStyle variant={isMobile ? 'sm-regular' : 'base-regular'}>
                {formatMessage(
                  isUploading ? messages.uploading : messages.uploaded
                )}
              </TextStyle>
              <OverflowText
                tooltipText={fileName}
                className="w-4/5 truncate"
                variant={isMobile ? 'sm-medium' : 'base-medium'}
              >
                {fileName}
              </OverflowText>
            </div>
            {!isMobile ? (
              <div className="flex gap-x-4">
                <div className="flex items-baseline gap-x-1">
                  <TextStyle variant="sm-regular" className="text-gray-8">
                    {formatMessage(messages.dateShared)}
                  </TextStyle>
                  <TextStyle variant="base-regular">
                    {formattedDateShared}
                  </TextStyle>
                </div>
                <div className="flex items-baseline gap-x-1">
                  <TextStyle variant="sm-regular" className="text-gray-8">
                    {formatMessage(messages.sharedIn)}
                  </TextStyle>
                  <button
                    onClick={handleSharedInClick}
                    className="flex text-primary-6 hover:text-primary-5"
                  >
                    <OverflowText
                      variant="base-medium"
                      tooltipText={sharedIn}
                      className="!block truncate"
                      tooltipClassname="h-auto break-all overflow-hidden"
                    >
                      {mapHexCodeToEmoticon(sharedInIcon?.value ?? '')}&nbsp;
                      {sharedIn}
                    </OverflowText>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Tooltip tooltipText={formatMessage(messages.close)}>
          <IconButton
            onClick={onClose}
            variation="tertiaryLite"
            className="flex-shrink-0"
          >
            <XMarkIcon className="h-6 w-6 text-gray-9" />
          </IconButton>
        </Tooltip>
      </header>
    </Dialog.Title>
  );
};
